<template>
  <div class="root-map">
    <div id="map">

    </div>
    <div class="input-bar">
      <autocomplete ref="autocomplete" auto-select :search="search" @submit="handleSubmit"
                    @focus="hideInfo"></autocomplete>

    </div>
    <!-- PUInfo ref="puinfoPane" :flyToCathID="flyToCathID" / -->
    <Info ref="infoPane" :auterParishShow="auterParishShow" :flyToCathID="flyToCathID"/>
  </div>
</template>

<script>

// import axios from 'axios'

import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import Info from '@/components/Info.vue'
// import PUInfo from '@/components/PUInfo.vue'


export default {
  components: {
    Autocomplete, Info /*, PUInfo */
  },
  name: 'Map',
  props: {
    msg: String,
  },
  data() {
    return {
      items: [],
      candidates: {},
      cath_id: 0,
      position: [],
      kind: 0,
      selected_layer: '',
      mapMarker: null,
      zoom: 17,
      epoint: null,
      layers_id: [],
      flying: false,
      loaded: false,
      searched: false,
      current_style: 'classic',
      currentUPLayer: '',
    }

  },
  mounted() {
    this.loadDataToStore()
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    loadMap() {
      mapboxgl.accessToken = this.$store.state.accessToken
      var style = ''
      if (!this.loaded) {
        if (this.$store.state.map_config.default_style == 'classic') {
          style = this.$store.state.style_classic
          this.current_style = 'classic'
        } else {
          style = this.$store.state.style_satellite
          this.current_style = 'satellite'
        }
        this.loaded = true;
      }
      this.$store.state.map = new mapboxgl.Map({
        container: 'map',
        style: style,
        center: this.$store.state.map_config.center,
        zoom: this.$store.state.map_config.default_zoom,
        attributionControl: false,
        minZoom: 7,
      })
      this.$store.state.map.on('click', (e) => {
        if (this.flying) {
          return false;
        }

        this.hideLayers()
        this.hideCurrentLayer()
        var coordinates = e.lngLat.wrap()
        this.epoint = e.point
        this.position = [coordinates.lng, coordinates.lat]
        this.$store.state.position = this.position
        this.kind = 5
        this.cath_id = 0
        this.hideInfo()
        this.setSearch()
        this.fly()
      })
      this.$store.state.map.on('flystart', () => {
        this.flying = true;
      })
      this.$store.state.map.on('flyend', () => {
        this.flying = false;
      })
      this.$store.state.map.on('moveend', () => {
        if (this.flying) {
          this.setMapMarkerPosition()
          this.$store.state.map.fire('flyend');
          setTimeout(() => this.checkLayers(), 280);

        }
      })
      this.$store.state.map.on('load', () => {
        this.loadLayers()
        this.$store.state.style_basic_loaded = true
      })
    },
    fly() {
      var zoom = this.$store.state.map_config.fly_zoom

      if (this.searched) {
        zoom = this.$store.state.map.getZoom()
      } else {
        this.searched = true
      }
      if (this.kind == 61) {
        zoom = this.$store.state.map_config.fly_zoom_up
        this.searched = false
      }
      if (this.kind == 5) {
        zoom = this.$store.state.map_config.fly_zoom_par
        this.searched = false
      }

      this.$store.state.map.flyTo({
        center: this.position,
        zoom: zoom,
        speed: this.$store.state.map_config.speed
      })
      this.$store.state.map.fire('flystart');
    },
    checkLayers() {
      // console.log('layer' , this)
      // console.log('layer' , this.currentUPLayer)
      this.$store.state.map.setLayerZoomRange(
          this.currentUPLayer,
          8.5,
          11.0
      );
      // console.log("checking layer intersetion with "+this.layers_id)
      var features = this.$store.state.map.queryRenderedFeatures([window.innerWidth / 2, window.innerHeight / 2], {
        layers: this.layers_id
      });

      if (this.kind > 0 && this.cath_id > 0) {

        if (this.cath_id in this.$store.state.cath_infos)
          if (this.$store.state.cath_infos[this.cath_id].type === 'parish') {

            // const  highlight_layer = features[0].layer

            const highlight_layer = this.$store.state.parishes.filter(parish => parish.cath_id === this.cath_id)
            this.showLayer(highlight_layer[0].layer_id)
            this.$refs.infoPane.toggleDialog(this.cath_id)
            this.selected_layer = highlight_layer[0].layer_id
          } else if (this.$store.state.cath_infos[this.cath_id].type === 'other') {
            const unit = this.$store.state.pastoral_units;
            const catUnit = unit.filter(unit => unit.cath_id === this.cath_id);
            const currentLayerId = catUnit[0].layer_id;
            console.log('currentID' , currentLayerId)
            this.showLayer(currentLayerId , 22)
            this.$refs.infoPane.toggleDialog(this.cath_id)
            this.selected_layer = currentLayerId
            this.currentUPLayer = currentLayerId;
          }
        // this.selected_layer = this.$store.state.cath_infos[this.cath_id].layer_id


        // if (this.kind == 5)
        //   this.$refs.infoPane.toggleDialog(this.cath_id)
        //
        // if (this.kind == 61)
        //   this.$refs.puinfoPane.toggleDialog(this.cath_id)
      } else {
        var title = "Pas de paroisse du diocèse à cet endroit."
        if (!features.length) {
          console.log(title)
        } else {
          // var polygonID = features[0].id;
          var layer = features[0].layer

          this.showLayer(layer.id)
          this.selected_layer = layer.id
          if (features[0].properties.cath_id > 0) {
//Question
//            title = this.$store.state.cath_infos[features[0].properties.cath_id].name_f
//             console.log(this.$refs.infoPane)
            this.$refs.infoPane.toggleDialog(features[0].properties.cath_id)
          }
        }
      }
    },
    loadLayers() {

      this.layers_id = []
      for (var i = 0; i < this.$store.state.parishes.length; i++) {
        var layer = this.$store.state.parishes[i]
        if (layer.active) {

          this.layers_id.push(layer['layer_id'])
          // if (layer['cath_id'])
          //   this.$store.state.cath_infos[layer['cath_id']].layer_id = layer['layer_id']
          this.$store.state.map.addSource(layer['layer_id'] + 'src', {
            type: 'vector',
            url: 'mapbox://' + layer['tileset_id']
          })
          this.addLayer(layer['layer_id'])
        }
      }

      for (var j = 0; j < this.$store.state.pastoral_units.length; j++) {
        var uplayer = this.$store.state.pastoral_units[j]
        // if (uplayer['cath_id'])
        // this.$store.state.cath_infos[uplayer['cath_id']].layer_id = uplayer['layer_id']

        if (uplayer.active) {
          this.layers_id.push(uplayer['layer_id'])
          this.$store.state.map.addSource(uplayer['layer_id'] + 'src', {
            type: 'vector',
            url: 'mapbox://' + uplayer['tileset_id']
          })
          this.addUPLayer(uplayer['layer_id'])
        }
      }
    },
    getLayerInfos(layer_id) {
      return layer_id
    },
    addLayer(layer_id) {
      var parentlayer = this.$store.state.map_config.parent_layer
      if (this.current_style == 'satellite') {
        parentlayer = 'road-path'
      }
      this.$store.state.map.addLayer({
        id: layer_id + 'line',
        type: 'line',
        'source': layer_id + 'src',
        'source-layer': layer_id, // name of tilesets
        'paint': {
          'line-color': 'rgba(87, 67, 100, 1)',
          'line-width': 1
        },
        "minzoom": 11,
        "maxzoom": 22.0,
      }, parentlayer)
      this.$store.state.map.addLayer({

        id: layer_id,
        type: 'fill',
        'source': layer_id + 'src',
        'source-layer': layer_id, // name of tilesets
        'paint': {
          'fill-color': 'rgba(87, 67, 100, 0.1)',
          'fill-opacity': 0.0,
          'fill-outline-color': '#111',
        },
        'layer': {
          'symbol': 'aaaaaaa'
        },
        "minzoom": 8.5,
        "maxzoom": 22.00,
      }, parentlayer)
    },
    addUPLayer(layer_id) {

      var parentlayer = this.$store.state.map_config.parent_layer

      if (this.current_style == 'satellite') {
        parentlayer = 'road-path'
      }
      this.$store.state.map.addLayer({
        id: layer_id,
        type: 'fill',
        'source': layer_id + 'src',
        'source-layer': layer_id, // name of tilesets
        'paint': {
          'fill-color': 'rgba(87, 67, 100, 0.7)',
          'fill-opacity': 0.0,
          'fill-outline-color': '#111',
        },
        "minzoom": 8.5,
        "maxzoom": 11.0,
      }, parentlayer)

      this.$store.state.map.addLayer({
        id: layer_id + 'line',
        type: 'line',
        'source': layer_id + 'src',
        'source-layer': layer_id, // name of tilesets
        'paint': {
          'line-color': 'rgba(87, 67, 100, 1)',
          'line-width': 1.5
        },
        "minzoom": 8.5,
        "maxzoom": 22.0,
      }, parentlayer)
    },
    showLayer(layer_id , zoom = 11) {
      this.$store.state.map.setPaintProperty(
          layer_id,
          'fill-opacity',
          0.5
      );
      this.$store.state.map.setPaintProperty(
          layer_id,
          'fill-color',
          'rgba(87, 67, 100, 0.8)'
      );
      this.$store.state.map.setPaintProperty(
          layer_id,
          'fill-outline-color',
          '#AA33DD'
      );

      if (zoom === 22) {
        this.$store.state.map.setLayerZoomRange(
            layer_id,
            8.5,
            zoom
        );
      }
    },
    removeLayers() {
      for (var i = 0; i < this.layers_id.length; i++) {
        this.$store.state.map.removeLayer(this.layers_id[i]);
        this.$store.state.map.removeSource(this.layers_id[i] + 'src');
      }
      this.layers_id = []
    },
    hideCurrentLayer() {
      if (this.selected_layer) {
        this.$store.state.map.setPaintProperty(
            this.selected_layer,
            'fill-opacity',
            0.0
        )
        // if (this.kind == 61){
        //     this.$store.state.map.setPaintProperty(
        //           this.selected_layer,
        //           'fill-opacity',
        //           0.0
        //     )
        // }else{
        //     this.$store.state.map.setPaintProperty(
        //           this.selected_layer,
        //           'fill-opacity',
        //           0.1
        //     )

        // }
        this.selected_layer = ''
      }
    },
    hideLayers() {
      for (var i = 0; i < this.$store.state.parishes.length; i++) {
        this.$store.state.map.setPaintProperty(
            this.$store.state.parishes[i].layer_id,
            'fill-opacity',
            0.0
        );
        this.$store.state.map.setPaintProperty(
            this.$store.state.parishes[i].layer_id,
            'fill-color',
            'rgba(255, 0, 0, 0.2)'
        );
        this.$store.state.map.setPaintProperty(
            this.$store.state.parishes[i].layer_id,
            'fill-outline-color',
            '#ff0000'
        );
      }
    },
    hideInfo() {
      this.$refs.infoPane.hide()
    },
    setSearch(value = '') {
      this.$refs.autocomplete.value = value
    },
    setStyle(style) {
      // this.removeLayers()
      if (style === 'satellite') {
        this.$store.state.map.setStyle(this.$store.state.style_satellite);
        this.current_style = 'satellite'
      } else {
        this.$store.state.map.setStyle(this.$store.state.style_classic);
        this.current_style = 'classic'
      }
      this.loadMap()
    },
    handleResize() {
      // this.window.width = window.innerWidth
      // this.window.height = window.innerHeight
      var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0]
      var mapCont = document.getElementById('map')
      mapCont.style.height = window.innerHeight + 'px'
      mapCanvas.style.height = window.innerHeight + 'px'
      this.$store.state.map.resize()
    },
    search(input) {
      this.items = []
      this.hideInfo()
      this.candidates = {}
      var formattedAddress = encodeURIComponent(input).replace(/%20/g, '+')
      var requestURL = this.$store.state.mapbox_geocoding + formattedAddress + '.json?types=address,locality,place,neighborhood,postcode&country=CH&access_token=' + this.$store.state.accessToken + '&limit=10'
      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([])
        }
        fetch(requestURL)
            .then(response => response.json())
            .then(data => {
              var results = data.features

              fetch(this.$store.state.map_config.internal_search + formattedAddress)
                  .then(response => response.json())
                  .then(data => {
                    var parishes = data
                    for (var g = 0; g < parishes.length; g++) {
                      this.items.push(parishes[g].place_name)
                      this.candidates[parishes[g].place_name] = parishes[g]

                    }
                    for (var i = 0; i < results.length; i++) {
                      if (results[i].relevance > 0.2) {

                        var region = ''
                        for (var j = 0; j < results[i].context.length; j++) {
                          if (results[i].context[j]['short_code']) {
                            region = results[i].context[j]['short_code']
                            break
                          }
                        }
                        if (this.$store.state.regions.indexOf(region) > -1) {
                          this.items.push(results[i].place_name.replace(", Switzerland", ""))
                          this.candidates[results[i].place_name.replace(", Switzerland", "")] = results[i]
                        }
                      }
                    }
                    return resolve(this.items)
                  })
            })


      })
    },
    // search_parishes(query){
    //   query = query.toLowerCase().normalize("NFD").replace(/\u002d/g, " ").replace(/\u0027/g, " ").replace(/[\u0300-\u036f]/g, "").replace(/[\u0021-\u002f]/g, "").replace(/[\u003a-\u0040]/g, "").replace(/[\u005b-\u0060]/g, "")
    //   var query_words = query.split(' ')
    //   var qlength = query_words.length
    //   var result = []
    //   var nb = 0 
    //   for (const norname in this.$store.state.indexed_parishes ){
    //     nb = 0
    //     for (var i=0; i<qlength; i++){
    //       if (norname.includes(query_words[i]))
    //         nb++;  
    //     }
    //     if (nb == qlength)
    //       result.push(this.$store.state.indexed_parishes[norname])

    //   }
    //   return result
    // },
    handleSubmit(result) {
      this.candidates[result]
      if (this.candidates[result]) {
        // this.hideLayers()
        this.hideCurrentLayer()
        this.hideInfo()
        var feature = this.candidates[result];

        this.position = [feature['center'][0], feature['center'][1]]
        this.kind = 0
        this.cath_id = 0
        if ("kind" in feature) {
          this.kind = feature['kind']
        }
        if ("cath_id" in feature) {
          this.cath_id = feature['cath_id']
        }
        this.$store.state.position = this.position
        this.fly()
      }
    },
    auterParishShow(elem) {
      // this.hideLayers()
      this.hideCurrentLayer()
      this.hideInfo()
      var feature = elem;
      console.log(elem)
      this.position = [feature['center'][0], feature['center'][1]]
      this.kind = 0
      this.cath_id = 0
      if ("kind" in feature) {
        this.kind = feature['kind']
      }
      if ("cath_id" in feature) {
        this.cath_id = feature['cath_id']
      }
      this.$store.state.position = this.position
      this.fly()
    },
    setMapMarkerPosition() {

      if (this.mapMarker != null) {
        this.mapMarker.remove()
      }
      var el = document.createElement('div');
      el.className = 'marker';
      this.mapMarker = new mapboxgl.Marker(el)
      console.log('marker' , this.position)
      this.mapMarker.setLngLat(this.position).addTo(this.$store.state.map)
    },
    loadDataToStore() {
      axios
          .get("config.json")
          .then(response => {
            this.$store.commit('SET_MAP', response.data.map);
            this.$store.commit('SET_PARISHES', response.data.parishes);
            this.$store.commit('SET_PASTORAL_UNITS', response.data.pastoral_units);
            axios
                .get("data.json")
                .then(response => {
                  this.$store.commit('SET_CATH_INFO', response.data);
                  setTimeout(() => this.$store.commit('INDEX_ENTITIES'), 700);
                })
            this.loadMap()
          })

    },
    flyToCathID(cath_id) {

      this.position = this.$store.state.cath_infos[cath_id].center
      this.kind = this.$store.state.cath_infos[cath_id].kind
      this.cath_id = cath_id
      // var layerid = this.$store.state.cath_infos[this.cath_id].layer_id
      // var features = this.$store.state.map.queryRenderedFeatures( {
      //     layers: layerid
      //   });
      // .getBounds().getCenter()
      this.fly()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
