import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    parishes:[],
    indexed_parishes:{},
    pastoral_units:[],
    indexed_pastoral_units:{},
    map_config:{},
    cath_infos:[],

    map:Object,
    accessToken: 'pk.eyJ1IjoiZGlvY2VzZS1sZ2YiLCJhIjoiY2tlMWd4bHFoMXExaTMwb2Fpbmx6ZDkyMyJ9.yAPQo34APKFWJYBstGt64A',
    style_classic: 'mapbox://styles/diocese-lgf/ckeuz4tvq4fjo19ui19l701o9',
    style_classic_loaded:false,
    style_satellite: 'mapbox://styles/diocese-lgf/ckgqpinr70dcx19qocssa44tl',
    style_satellite_loaded:false,
    center: [6.915746, 46.720509], // starting position [lng, lat]
    zoom: 7.5,
    position : [],
    mapbox_geocoding: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
    regions: ['CH-FR', 'CH-VD', 'CH-NE', 'CH-GE'],

  },
  mutations: {
    SET_MAP: (state, map) => {
      state.map_config = map;
    },
    SET_PARISHES: (state, parishes) => {
      state.parishes = parishes;
      
    },
    SET_PASTORAL_UNITS: (state, pastoral_units) => {
      state.pastoral_units = pastoral_units;
    },
    SET_CATH_INFO: (state, infos) => {
      state.cath_infos = infos;
    },
    INDEX_ENTITIES: (state) => {
      for(var i=0;i<state.pastoral_units.length;i++){
        if('cath_id' in state.pastoral_units[i]){
          state.cath_infos[state.pastoral_units[i]['cath_id']]['parishes'] = state.pastoral_units[i].parishes
          state.cath_infos[state.pastoral_units[i]['cath_id']]['center'] = state.pastoral_units[i].center
        }
      }
      for(i=0;i<state.parishes.length;i++){
        var std_name = ''
        if (!(state.parishes[i]['cath_id'] in state.cath_infos))
        continue;
        state.cath_infos[state.parishes[i]['cath_id']]['center'] = state.parishes[i].center

        if (state.cath_infos[state.parishes[i]['cath_id']]['name_f']){
          std_name = state.cath_infos[state.parishes[i]['cath_id']]['name_f'].toLowerCase().normalize("NFD").replace(/\u002d/g, " ").replace(/\u0027/g, " ").replace(/[\u0300-\u036f]/g, "").replace(/[\u0021-\u002f]/g, "").replace(/[\u003a-\u0040]/g, "").replace(/[\u005b-\u0060]/g, "")
          state.indexed_parishes[std_name] = {}
          state.indexed_parishes[std_name]['cath_id']=state.parishes[i]['cath_id']
          state.indexed_parishes[std_name]['place_name']=state.cath_infos[state.parishes[i]['cath_id']]['name_f']
          state.indexed_parishes[std_name]['center']=[state.cath_infos[state.parishes[i]['cath_id']]['lng'], state.cath_infos[state.parishes[i]['cath_id']]['lat']]
            state.indexed_parishes[std_name]['lang'] = 'f'
            state.indexed_parishes[std_name]['kind'] = state.cath_infos[state.parishes[i]['cath_id']]['kind']
        }
        if (state.cath_infos[state.parishes[i]['cath_id']]['name_d']){

          std_name = state.cath_infos[state.parishes[i]['cath_id']]['name_d'].toLowerCase().normalize("NFD").replace(/\u002d/g, " ").replace(/\u0027/g, " ").replace(/[\u0300-\u036f]/g, "").replace(/[\u0021-\u002f]/g, "").replace(/[\u003a-\u0040]/g, "").replace(/[\u005b-\u0060]/g, "")
        if(std_name.includes('paroisse')){
          continue;
        }
          state.indexed_parishes[std_name] = {}
          state.indexed_parishes[std_name]['cath_id']=state.parishes[i]['cath_id']
          state.indexed_parishes[std_name]['place_name']=state.cath_infos[state.parishes[i]['cath_id']]['name_d']
          state.indexed_parishes[std_name]['center']=[state.cath_infos[state.parishes[i]['cath_id']]['lng'], state.cath_infos[state.parishes[i]['cath_id']]['lat']]
            state.indexed_parishes[std_name]['lang'] = 'd'

        }
        if (state.cath_infos[state.parishes[i]['cath_id']]['name_e']){
          std_name = state.cath_infos[state.parishes[i]['cath_id']]['name_e'].toLowerCase().normalize("NFD").replace(/\u002d/g, " ").replace(/\u0027/g, " ").replace(/[\u0300-\u036f]/g, "").replace(/[\u0021-\u002f]/g, "").replace(/[\u003a-\u0040]/g, "").replace(/[\u005b-\u0060]/g, "")
          state.indexed_parishes[std_name] = {}
          state.indexed_parishes[std_name]['cath_id']=state.parishes[i]['cath_id']
          state.indexed_parishes[std_name]['place_name']=state.cath_infos[state.parishes[i]['cath_id']]['name_e']
          state.indexed_parishes[std_name]['center']=[state.cath_infos[state.parishes[i]['cath_id']]['lng'], state.cath_infos[state.parishes[i]['cath_id']]['lat']]
            state.indexed_parishes[std_name]['lang'] = 'e'
        }
        if (state.cath_infos[state.parishes[i]['cath_id']]['name_i']){
          std_name = state.cath_infos[state.parishes[i]['cath_id']]['name_i'].toLowerCase().normalize("NFD").replace(/\u002d/g, " ").replace(/\u0027/g, " ").replace(/[\u0300-\u036f]/g, "").replace(/[\u0021-\u002f]/g, "").replace(/[\u003a-\u0040]/g, "").replace(/[\u005b-\u0060]/g, "")
          state.indexed_parishes[std_name] = {}
          state.indexed_parishes[std_name]['cath_id']=state.parishes[i]['cath_id']
          state.indexed_parishes[std_name]['place_name']=state.cath_infos[state.parishes[i]['cath_id']]['name_i']
          state.indexed_parishes[std_name]['center']=[state.cath_infos[state.parishes[i]['cath_id']]['lng'], state.cath_infos[state.parishes[i]['cath_id']]['lat']]
            state.indexed_parishes[std_name]['lang'] = 'i'
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
