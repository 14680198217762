<template>
  <transition name="slide">
    <div class="slidein info" v-if="displayed">

      <button @click="toggleDialog" class="close-left button close-button"><i class="las la-arrow-right"> </i>
      </button>

      <div class="slide-content">

        <app-accordion v-if="parishInfo" :isOpen="openParish">
          <template v-slot:title>
            <span class="">Informations de la Paroisse</span>
          </template>
          <template v-slot:content>
            <div v-if="openParish">
            <div class="p-4 pt-0">
              <h2>{{ title }}</h2>
              <div class="row">
                <div class="col-12 d-flex flex-row" v-if="address">
                  <div class="cath-info-icon"><i class="las la-map-marker"></i></div>
                  <div class=""><span v-html="address"></span></div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="web">
                  <div class="cath-info-icon"><i class="las la-link"></i></div>
                  <div class=""><a :href="web" target="_blank" class="">{{ web }}</a></div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="phone">
                  <div class="cath-info-icon"><i class="las la-phone"></i>
                  </div>
                  <div class="">
                    <a :href="'tel:'+phone" class="">{{ phone }}</a>
                  </div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="email">
                  <div class="cath-info-icon"><i class="las la-at"></i>
                  </div>
                  <div class="">
                    <a :href="'mailto:'+email" class="btn btn-light btn-sm">{{ email }}</a>
                  </div>
                </div>

                <div v-if="pers.length > 0" class="persons mt-4 pt-4">
                  <div class="col-12 d-flex flex-row mb-3" v-for="elem in pers" :key="elem">
                    <div class="cath-info-icon"><i class="las la-address-card"></i>
                    </div>
                    <div class="" v-html="elem"></div>
                  </div>
                </div>

              </div>
            </div>
            </div>
          </template>
        </app-accordion>

        <app-accordion :isOpen="openUP">
          <template v-slot:title>
            <span class="">Unité pastorale</span>
          </template>
          <template v-slot:content>
            <div class="p-4 pt-0">
              <h2 class="up-title-link" @click="showParish(up_item)">{{ up_title }}</h2>
              <div class="row">
                <div class="col-12 d-flex flex-row" v-if="up_address">
                  <div class="cath-info-icon"><i class="las la-map-marker"></i></div>
                  <div class=""><span v-html="up_address"></span></div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="up_web">
                  <div class="cath-info-icon"><i class="las la-link"></i></div>
                  <div class=""><a :href="up_web" target="_blank" class="">{{ up_web }}</a></div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="up_phone">
                  <div class="cath-info-icon"><i class="las la-phone"></i>
                  </div>
                  <div class="">
                    <a :href="'tel:'+up_phone" class="">{{ up_phone }}</a>
                  </div>
                </div>

                <div class="col-12 d-flex flex-row" v-if="up_email">
                  <div class="cath-info-icon"><i class="las la-at"></i>
                  </div>
                  <div class="">
                    <a :href="'mailto:'+up_email" class="btn btn-light btn-sm">{{ up_email }}</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </app-accordion>

        <app-accordion v-if="unitInfo" :isOpen="openUP">
          <template v-slot:title>
            <span class="">Autres paroisses</span>
          </template>
          <template v-slot:content>
            <div class="p-4 pt-0">
              <div class="col-12 d-flex flex-row mb-3" v-for="parish in auterParishes" :key="parish.place_name">
                <div class="cath-info-icon"><i class="las la-link"></i>
                </div>
                <div class="parish-name" @click="showParish(parish)"><span>{{ parish.place_name }}</span></div>
              </div>
            </div>
          </template>
        </app-accordion>

        <!-- div class="col-12 d-flex flex-row" v-if="pers_277">
          <div class="cath-info-icon" ><i class="las la-address-card"></i>
          </div>
          <div class=""  v-html="pers_277"></div>
        </div -->

        <!--
        <div class="row">
          <div class="col-xs-12">
            <h2>{{ this.title }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><br><br>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <h2 class="uptitle"><a @click="loadCathID(up_cath_id)" class=" button"><i class="las la-info"></i>{{ this.up_title }}</a></h2>
          </div>
        </div>
        <div class="row cath-info">
           <div class="col-sm-6 col-12 d-flex flex-row" v-if="up_address">
            <div class="cath-info-icon" ><i class="las la-home"></i>
            </div>
            <div class="" ><span v-html="this.up_address"></span>
            </div>
          </div>

          <div class="col-sm-6 col-12 d-flex flex-row" v-if="up_phone">
            <div class="cath-info-icon"><i class="las la-phone"></i>
            </div>
            <div class="" >
              <a :href="'tel:'+this.up_phone" class="">{{ this.up_phone }}</a>
            </div>
          </div>

          <div class="col-sm-6 col-12 d-flex flex-row" v-if="up_web">
            <div class="cath-info-icon"><i class="las la-link"></i>
            </div>
            <div class="">
              <a :href="this.up_web" target="_blank" class="">Site web</a>
            </div>
          </div>

          <div class="col-sm-6 col-12 d-flex flex-row" v-if="up_email">
            <div class="cath-info-icon"><i class="las la-at"></i>
            </div>
            <div class="">
              <a :href="'mailto:'+up_email" class="btn btn-light btn-sm">{{ up_email }}</a>
            </div>
          </div>

        </div>
        -->

      </div>
    </div>
  </transition>
</template>

<script>
import AppAccordion from "./AppAccordion";

export default {
  name: 'Info',
  components: {
    AppAccordion,
  },
  props: ['flyToCathID', 'auterParishShow'],
  data() {
    return {
      displayed: false,
      cath_id: 0,
      title: '',
      address: '',
      phone: '',
      email: '',
      web: '',
      pers_213: '',
      pers_277: '',
      pers: [],
      up_title: '',
      up_web: '',
      up_cath_id: 0,
      up_address: '',
      up_phone: '',
      up_email: '',
      staticURL: '',
      up_item: {},
      auterParishes: [],
      openParish: true,
      openUP: false,
      parishInfo: true,
      unitInfo: true
    }
  },
  methods: {
    hide() {
      this.displayed = false
    },
    toggleAccType(kind = 5) {
      if (parseInt(kind) === 61) {
        this.parishInfo = false
        this.unitInfo = true
        this.openParish = false
        this.openUP = true
      } else {
        this.parishInfo = true
        this.openParish = true
        this.openUP = false
        this.unitInfo = false
      }
    },
    showParish(parish) {
      this.auterParishShow(parish)
    },
    toggleDialog(id = 0) {
      const cathInfoItem = this.$store.state.cath_infos[id]
      if(cathInfoItem === undefined){
        this.displayed = !this.displayed
        return this.displayed
      }
      const itemKind = this.$store.state.cath_infos[id].kind;

      this.toggleAccType(itemKind);
      if (id > 0) {
        this.cath_id = id

        this.title = this.$store.state.cath_infos[this.cath_id].name_f
        this.staticURL = 'https://api.mapbox.com/styles/v1/diocese-lgf/ckeuz4tvq4fjo19ui19l701o9/static/' + this
            .$store.state.position[0] + ',' + this.$store.state.position[1] + ',12/568x200?access_token=' + this.$store
            .state.accessToken

        this.address =
            this.$store.state.cath_infos[this.cath_id].street_nr
        if (this.$store.state.cath_infos[this.cath_id].po_box) {
          this.address += '<br>Case postale ' + this.$store.state.cath_infos[this.cath_id].po_box
        }
        if (this.$store.state.cath_infos[this.cath_id].location) {
          this.address += '<br>' + this.$store.state.cath_infos[this.cath_id].postal_code + ' ' + this.$store.state
              .cath_infos[this.cath_id].location
        }
        this.phone = this.$store.state.cath_infos[this.cath_id].phone
        this.email = this.$store.state.cath_infos[this.cath_id].email
        this.web = this.$store.state.cath_infos[this.cath_id].web
        this.pers_213 = ''

        this.pers = [];
        for (let i in this.$store.state.cath_infos[this.cath_id].roles) {
          let elem = this.$store.state.cath_infos[this.cath_id].roles[i];

          let pers_str = '<b>' + elem.func_f + '</b><br>';

          if (elem.anrede) {
            pers_str += elem.anrede + '<br>'
          }
          pers_str += elem.first_name + ' ';
          pers_str += elem.name + '<br>';

          if (elem.phone) {
            pers_str += 'Téléphone: <a href="tel:' + elem.phone + '">' + elem.phone + '</a><br>';
          }
          if (elem.mobile) {
            pers_str += 'Mobile: <a href="tel:' + elem.mobile + '">' + elem.mobile + '</a><br>';
          }
          if (elem.mail) {
            pers_str += 'Email: <a href="mailto:' + elem.mail + '">' + elem.mail + '</a><br>';
          }
          if (elem.street_nr) {
            pers_str += 'Adresse: ' + elem.street_nr + '<br>';
          }
          if (elem.postal_code || elem.location) {
            pers_str += elem.postal_code + ' ';
            pers_str += elem.location + ' ';
          }


          this.pers.push(pers_str);
        }
        // if (this.$store.state.cath_infos[this.cath_id].roles[213]) {
        //   this.pers_213 = ' <b>' + this.$store.state.cath_infos[this.cath_id].roles[213].func_f + '</b><br>'
        //   this.pers_213 += this.$store.state.cath_infos[this.cath_id].roles[213].anrede + '<br>'
        //   this.pers_213 += this.$store.state.cath_infos[this.cath_id].roles[213].first_name + ' '
        //   this.pers_213 += this.$store.state.cath_infos[this.cath_id].roles[213].name + '<br>'
        //
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].phone + '<br>'
        //
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].mobile + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].mail + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].street_nr + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].postal_code + ' '
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[213].location + ' '
        // }
        // this.pers_277 = ''
        // if (this.$store.state.cath_infos[this.cath_id].roles[277]) {
        //   this.pers_277 = ' <b>' + this.$store.state.cath_infos[this.cath_id].roles[277].func_f + '</b><br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].anrede + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].first_name + ' '
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].name + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].phone + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].mobile + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].mail + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].street_nr + '<br>'
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].postal_code + ' '
        //   this.pers_277 += this.$store.state.cath_infos[this.cath_id].roles[277].location + ' '
        // }
        var cath_up_id = this.$store.state.cath_infos[this.cath_id].parent ? this.$store.state.cath_infos[this.cath_id].parent : this.cath_id;
        this.up_cath_id = cath_up_id
        this.up_item = this.$store.state.cath_infos[cath_up_id]
        this.up_item.cath_id = cath_up_id
        this.up_title = this.$store.state.cath_infos[cath_up_id].name_f
        // Get auter parishes list
        const parishes = this.$store.state.cath_infos[cath_up_id].parishes

        if (parishes !== undefined) {
          // const activeParishes = Object.values(this.$store.state.indexed_parishes).filter( parish => parishes.includes(parish.cath_id)).map(parish => parish.place_name)
          const activeParishes = Object.values(this.$store.state.indexed_parishes).filter(parish => parishes.includes(parish.cath_id) && parish.lang === 'f')
          // const uniqueKey = 'cath_id';
          // const arrayUniqueByKey = [...new Map(activeParishes.map(item =>
          //     [item[uniqueKey], item])).values()];
          this.auterParishes = [...activeParishes];
        }


        this.up_address = this.$store.state.cath_infos[cath_up_id].street_nr
        if (this.$store.state.cath_infos[cath_up_id].po_box) {
          this.up_address += '<br>Case postale' + this.$store.state.cath_infos[cath_up_id].po_box
        }
        this.up_address += '<br>' + this.$store.state.cath_infos[cath_up_id].postal_code + ' ' + this.$store.state
            .cath_infos[cath_up_id].location
        this.up_phone = this.$store.state.cath_infos[cath_up_id].phone
        this.up_email = this.$store.state.cath_infos[cath_up_id].email
        this.up_web = this.$store.state.cath_infos[cath_up_id].web
      }


      this.displayed = !this.displayed
      return this.displayed
    },
    loadCathID(cath_id) {
      this.flyToCathID(cath_id)
      this.displayed = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.col-icon {
  flex: 0 0 auto;
  width: 10px;
}

.cath-info {
  padding-bottom: 20px;
}

.cath-info > div {
  margin-bottom: 20px;
}

.cath-info-icon {
  width: 32px;
  min-width: 32px;

}

.slidein {
  width: 100%;
  max-width: 550px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background: #FFF;
  height: 100%;
  max-height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

.persons {
  border-top: 1px solid #000;
}

b {
  margin-bottom: 15px;
}

.slide-enter,
.slide-leave-active {
  right: -100%;
}

.slide-content {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

h2,
.h2 {
  font-size: 25px;
  font-weight: bold;
  padding: 0.3rem;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #ba0000;
  text-decoration: none;
}

i.las {
  font-size: 24px;
}

a:hover, a:focus {
  color: #ba0000;
  text-decoration: underline;
}

.uptitle a {
  text-decoration: none;
  color: #FFF;
}

.uptitle a:hover, .uptitle a:focus {
  text-decoration: underline;
}

.close-left {
  position: absolute;
  top: 110px;
  left: -50px;
  background-color: #fff;
}

.close-left .las {
  font-size: 1.5rem;
}

.close-button {
  border-radius: 0;
  border-right: none;
  border-width: 0;
  height: 50px;
  width: 50px;
}

.col-sm-12.preview {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.minipointer {
  position: absolute;
  font-size: 3rem;
  color: #ba0000;
  margin-left: 50%;
  margin-right: 50%;
  width: 48px;
  height: 48px;
  top: 76px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 650px) {
  .slidein {
    max-width: 500px;
  }

  .preview img {
    width: 100%;
    height: auto;
  }

  .col-sm-12.preview {
    width: 100%;
    height: auto;
  }

  .slide-content {
    overflow-x: hidden;
    /* padding-top: 60px; */
  }

  .close-left {
    position: absolute;
    top: 10px;
    left: -50px;
    background-color: #fff;
  }


  .minipointer {
    top: 42px;
  }
}

@media (max-width: 550px) {
  .slidein {
    max-width: 430px;
    width: 100%;
    padding: 0.5em 0.5em;
  }
}

@media (max-width: 480px) {
  .slidein {
    max-width: 420px;
  }

  .close-button {
    height: 40px;
    width: 30px;
  }

  .close-left {
    position: absolute;
    top: 6px;
    left: -30px;
    background-color: #fff;
  }

  .close-left .las {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

@media (max-width: 375px) {
  .slidein {
    max-width: 345px;
  }

  .minipointer {
    top: 30px;
  }

  .close-button {
    height: 40px;
    width: 30px;
  }

  .close-left {
    position: absolute;
    top: 6px;
    left: -30px;
    background-color: #fff;
    padding: 0;
  }

  .close-left .las {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

@media (max-width: 360px) {
  .slidein {
    max-width: 330px;
  }

  .close-button {
    height: 40px;
    width: 30px;
  }

  .minipointer {
    top: 24px;
  }

  .close-left {
    position: absolute;
    top: 6px;
    left: -30px;
    background-color: #fff;
  }

  .close-left .las {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

.parish-name span {
  color: #ba0000;
  cursor: pointer;
}

.up-title-link {
  color: #ba0000;
  cursor: pointer;
}
</style>
<style>
.persons a {
  text-decoration: none;
}

.persons a:hover {
  color: #ba0000;
  text-decoration: underline;
}
</style>