<template>
  <div>
    <button
        @click="toggleAccordion()"
        class="flex items-center space-x-3"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${_uid}`"
    >
      <slot name="title" />
      <svg
          class="rotate"
          :class="{
          'rotate-180': isOpen,
          'rotate-0': !isOpen,
        }"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 10"
          aria-hidden="true"
      >
        <path
            d="M15 1.2l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>
    </button>

    <transition name="fade">
      <div v-show="isOpen" :id="`collapse${_uid}`">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
button,
button:active,
button:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-top: 1px solid #7f7f7f !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 100%;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #ba0000;
  font-size: 30px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 20px 30px;
}
svg {
  max-width: 15px;
  color: #000;
}
.fade-enter-active, .fade-leave-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 0.5s ease-out;
}
.fade-enter, .fade-leave-to {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
.rotate.rotate-0 {
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);

  transition: all 0.35s;
}
.rotate.rotate-180 {
  transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);

  transition: all 0.35s;
}
</style>